
import Vue from "vue"
import type { SwiperOptions } from "swiper"
import type { Partner } from "@/types/api/partner"
import breakpoints from "@/assets/style/_breakpoints.scss"

export default Vue.extend({
  data: () => ({
    partners: [] as Partner[],
  }),

  async fetch () {
    await this.fetchPartners()
  },

  computed: {
    swiperOptions (): SwiperOptions {
      return {
        freeMode: false,
        spaceBetween: 17,
        slidesPerView: 2,
        breakpoints: {
          [breakpoints.xs]: {
            slidesPerView: 3,
          },
          [breakpoints.sm]: {
            slidesPerView: 4,
          },
        },
      }
    },
  },

  methods: {
    async fetchPartners (): Promise<void> {
      const [res, err] = await this.$api.partner.getPartners({
        params: {
          // "filter[front]": true,
          "sort[list]": "asc",
        },
      })
      if (err) return console.error(err)
      if (!res) return

      this.partners = this.$api.helpers.getList(res)
    },
  },
})
