import { render, staticRenderFns } from "./index.vue?vue&type=template&id=aa7cec18"
import script from "./index.vue?vue&type=script&lang=ts"
export * from "./index.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LandingHero: require('/app/components/landing/LandingHero.vue').default,LandingLead: require('/app/components/landing/LandingLead.vue').default,LandingScope: require('/app/components/landing/LandingScope.vue').default,LandingEvents: require('/app/components/landing/LandingEvents.vue').default,LandingMap: require('/app/components/landing/LandingMap.vue').default,LandingNumbers: require('/app/components/landing/LandingNumbers.vue').default,LandingStagesNew: require('/app/components/landing/LandingStagesNew.vue').default,LandingPartners: require('/app/components/landing/LandingPartners.vue').default,LandingCta: require('/app/components/landing/LandingCta.vue').default})
