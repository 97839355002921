
import Vue from "vue"
import { mapState, mapGetters } from "vuex"

export default Vue.extend({
  computed: {
    ...mapState("app", ["votingEnabled", "stage3"]),
    ...mapGetters("user", ["isAdmin"]),
  },
})
