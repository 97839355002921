
import Vue from "vue"
import { mapState, mapGetters } from "vuex"
import "aos/dist/aos.css"
import AOS from "aos"

export default Vue.extend({
  layoutSettings: { fullWidth: true, whiteHeader: true, noPaddingTop: true },

  head: {
    link: [{
      rel: "preload",
      as: "font",
      crossorigin: "crossorigin",
      type: "font/otf",
      href: "/fonts/DrukCyrWide/DrukCyrWide-Medium.otf",
    }],
  },

  computed: {
    ...mapState("app", ["votingEnabled", "stage3"]),
    ...mapGetters("user", ["isAdmin"]),
  },

  async mounted () {
    setTimeout(() => { AOS.init({ once: true }) }, 500)

    // Круги при клике
    const mojs = await import("@mojs/core")
    const OPTS = {
      fill: "none",
      radius: 25,
      strokeWidth: { 50: 0 },
      scale: { 0: 1 },
      duration: 500,
      left: 0,
      top: 0,
      easing: "cubic.out",
    }
    const mainCircle = new mojs.Shape({ ...OPTS, stroke: "#B58CED" })
    const smallCircles: any[] = []
    const colors = ["#60CD7C", "#EB8CED"]
    for (let i = 0; i < 4; i++)
      smallCircles.push(new mojs.Shape({
        ...OPTS,
        parent: mainCircle.el,
        strokeWidth: { 30: 0 },
        left: "50%",
        top: "50%",
        stroke: colors[i % colors.length],
        delay: "rand(0, 350)",
        x: "rand(-50, 50)",
        y: "rand(-50, 50)",
        radius: "rand(5, 20)",
      }))
    document.querySelectorAll("[data-circles]").forEach((el) => {
      el.addEventListener("mousedown", (e: any) => {
        mainCircle.tune({ x: e.pageX, y: e.pageY }).replay()
        for (let i = 0; i < smallCircles.length; i++)
          smallCircles[i].generate().replay()
      })
    })
  },
})
