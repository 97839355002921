
import eventMixin from "@/mixins/event"
import type { ExhibitionEvent } from "@/types/api/exhibition"

export default eventMixin.extend({
  props: {
    event: { type: Object as VuePropType<ExhibitionEvent>, required: true },
    isBrief: { type: Boolean, default: false },
  },

  computed: {
    date (): string {
      const { fmtDate } = this
      const { start, finish } = this.event
      const startFmtd = fmtDate(start)
      if (start === finish) return startFmtd
      else return `${startFmtd} –<br>${fmtDate(finish)}`
    },

    isFederal (): boolean {
      return this.event.area === "federal"
    },

    address (): string {
      const { needsHolder, address } = this.event
      return needsHolder
        ? [address.region, address.city].filter(Boolean).join(", ")
        : address.result
    },

    holderAddress (): string {
      const holder = this.event.holders[0]
      if (!holder) return ""
      return `${holder.test} — ${holder.address}`
    },
  },

  methods: {
    fmtDate (date: number): string {
      return this.$fmt.date(date, "dd.MM", { asIs: this.dateAsIs, local: !this.dateAsIs })
    },
  },
})
