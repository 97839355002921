
import Vue from "vue"
import { mapState, mapGetters } from "vuex"
import sample from "lodash/sample"
import anime from "animejs/lib/anime.es"
import throttle from "lodash/throttle"

type Space =
  | "beauty"
  | "culture"
  | "entertainment"
  | "fashion"
  | "food"
  | "hotel"
  | "library"
  | "loft"
  | "sport"
interface City { name: string, spaces: Space[], x: number, y: number }

const autoplayInterval = 5000

export default Vue.extend({
  data: () => ({
    cities: [
      {
        name: "Кострома",
        spaces: ["loft", "entertainment", "beauty", "sport"],
        x: 19,
        y: 46,
      },
      {
        name: "Омск",
        spaces: ["sport", "food", "fashion"],
        x: 35,
        y: 73,
      },
      {
        name: "Новосибирск",
        spaces: ["culture", "hotel", "library"],
        x: 41,
        y: 78,
      },

      {
        name: "Воронеж",
        spaces: ["loft", "entertainment", "beauty"],
        x: 10,
        y: 54,
      },
      {
        name: "Симферополь",
        spaces: ["sport", "food", "fashion"],
        x: 0,
        y: 54,
      },
      {
        name: "Краснодар",
        spaces: ["culture", "hotel", "library"],
        x: 3,
        y: 64,
      },
      {
        name: "Грозный",
        spaces: ["loft", "entertainment", "beauty"],
        x: 6,
        y: 74,
      },
      {
        name: "Салехард",
        spaces: ["sport", "food", "fashion"],
        x: 36,
        y: 45,
      },
      {
        name: "Красноярск",
        spaces: ["culture", "hotel", "library"],
        x: 49,
        y: 78,
      },
      {
        name: "Якутск",
        spaces: ["loft", "entertainment", "beauty"],
        x: 68,
        y: 61,
      },
      {
        name: "Хабаровск",
        spaces: ["sport", "food", "fashion"],
        x: 79,
        y: 67,
      },
      {
        name: "Каменское",
        spaces: ["culture", "hotel", "library"],
        x: 90,
        y: 27,
      },
      {
        name: "Петропавловск-Камчатский",
        spaces: ["loft", "entertainment", "beauty"],
        x: 94,
        y: 47,
      },
    ] as City[],

    spacesTitles: {
      beauty: "Красота",
      culture: "Культура",
      entertainment: "Развлечения",
      fashion: "Мода",
      food: "Еда",
      hotel: "Отели и хостелы",
      library: "Библиотеки",
      loft: "Лофт",
      sport: "Спорт",
    } as Record<Space, string>,

    isAutoplayEnabled: true,
    autoplayTimer: undefined as Timer,
    tickerText: "Добавь своё пространство на карту россии",
    tickerX: 0,
  }),

  computed: {
    ...mapState("app", ["votingEnabled", "stage3"]),
    ...mapGetters("app", ["mapVisible"]),
    ...mapGetters("user", ["isAdmin"]),

    newView (): boolean {
      return this.mapVisible
    },
  },

  mounted () {
    this.autoplayTimer = setInterval(this.autoplay, autoplayInterval)
    setTimeout(() => {
      this.onScroll()
      window.addEventListener("scroll", this.onScroll, { passive: true })
    }, 100)
  },
  beforeDestroy () {
    clearInterval(this.autoplayTimer)
    window.removeEventListener("scroll", this.onScroll)
  },

  methods: {
    autoplay () {
      if (!this.isAutoplayEnabled) return
      const points = (this.$refs.map as Element)
        ?.querySelectorAll("[data-point]") || []
      const point = sample(points)
      if (!point) return
      this.openPoint(point)
      setTimeout(() => this.closePoint(point), 0.5 * autoplayInterval)
    },

    openPoint (el: Element) {
      anime({
        targets: el.querySelectorAll("[data-space]"),
        scale: [0, 0.6],
        translateX: (_el: Element, i: number) => `${105 * (i - 1)}%`,
        translateY: (_el: Element) => `${anime.random(-20, 20)}%`,
        // delay: () => anime.random(0, 300),
      })
    },
    closePoint (el: Element) {
      anime({
        targets: el.querySelectorAll("[data-space]"),
        scale: 0,
        translateX: 0,
        translateY: 0,
        easing: "easeOutExpo",
      })
    },

    onMouseEnter (e: MouseEvent | FocusEvent) {
      const target = e.target as Element
      if (!target) return
      this.openPoint(target)
    },
    onMouseLeave (e: MouseEvent | FocusEvent) {
      const target = e.target as Element
      if (!target) return
      this.closePoint(target)
    },

    onScroll: throttle(function (this: any) {
      const rect = (this.$refs.ticker as Element)?.getBoundingClientRect()
      if (!rect) return
      this.tickerX = rect.y / window.innerHeight
    }, 1000 / 90),
  },
})
