
import Vue from "vue"
import { mapGetters } from "vuex"
import type { ExhibitionEvent } from "@/types/api/exhibition"
import breakpoints from "@/assets/style/_breakpoints.scss"

export default Vue.extend({
  data: () => ({
    events: [] as ExhibitionEvent[],
    step: 0,
    count: 5,
    onScreen: 2,
    interval: 3,
    timer: undefined as Timer,
  }),

  async fetch () {
    await this.fetchEvents()
  },

  head () {
    // @ts-ignore
    return { title: this.getTitle(this.title) }
  },

  computed: {
    ...mapGetters("app", ["getTitle"]),
  },

  mounted () {
    this.timer = setInterval(this.autoSwitch, this.interval * 1000)
  },
  beforeDestroy () {
    clearInterval(this.timer)
  },

  methods: {
    async fetchEvents () {
      let events = await this.getEvents({ "filter[listedOnIndexPage]": true })
      const { length } = events

      if (length < this.count) {
        const defaultEvents = await this.getEvents({
          "paginator[limit]": this.count - length,
          "filter[listedOnIndexPage]": false,
        })
        events = [...events, ...defaultEvents]
      }

      this.events = events
    },

    async getEvents (params = {}) {
      const [res, err] = await this.$api.exhibition.getEvents({ params })
      if (err) {
        console.error(err)
        return []
      }
      if (!res) return []

      return this.$api.helpers.getList(res)
    },

    switchSlide (direction = "right") {
      clearInterval(this.timer)
      if (direction === "right") {
        if (this.step < 1) return
        this.step--
      } else {
        this.onScreen = window.innerWidth >= breakpoints.xs ? 2 : 1
        if (this.step + this.onScreen >= this.events.length) return
        this.step++
      }
    },

    autoSwitch () {
      if (this.step + this.onScreen >= this.events.length) this.step = 0
      else this.step++
    },
  },
})
