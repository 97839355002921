import Vue from "vue"

export default Vue.extend({
  computed: {
    dateAsIs (): boolean {
      // @ts-ignore
      return (this.event?.creationTime || 0) > 1701216000000
    },
  },
})
